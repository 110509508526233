// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

@import url("https://use.typekit.net/qrb4iko.css");
@import url('https://fonts.googleapis.com/css?family=Suez+One&display=swap');


* {
  font-family: sofia-pro-soft,sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  
}

h4, b, strong {
    font-family: sofia-pro-soft,sans-serif;
    font-weight: 700;
    font-style: normal;
}
h1.display-4 {
  letter-spacing: .01em;
}
h1, h2, h3, h4,
.h1, .h2, .h3, .h4, 
h1 span, h2 span, h3 span, h4 span {
    font-family: 'Suez One', serif;
    font-weight: normal;
    letter-spacing: .01em;
}

h1 span, h2 span, h3 span, h4 span {
    color: $primary;
}

p {
  a{
    font-weight: inherit;
    // text-decoration: underline;
    border-bottom: 1px solid rgba($white,0);
    .bg-primary-3 & {
      color: $primary !important;
    }
    .bg-primary & {
      color: $primary-2 !important;
    }
    &:hover {
      border-bottom: 1px $primary solid;
      transition: border-bottom 0.3s ease-in-out;
    }
  }
}

@each $name, $color in $theme-color-scheme {
  .bg-#{$name}-alt{
    background: mix($color,$white,30%) !important;
  }
}

i {
  border: none;
}

.custom-control-input:checked ~ .custom-control-label:before {
  border-color: $primary !important;
  background-color: $primary;
}

form {
  .mauticform-label { 
    @extend .custom-control-label;
  }
  .custom-radio {
    // @extend .
  }
  .custom-control-input {
    @extend .custom-control-input;
  }
  .hdor {
    &:before {
      display: none;
    }
  }
}