// 
// 
// image-collage.scss
//
// 

.image-collage{
  position: relative;
  display: flex;
  justify-content: center;
  > a{
    z-index: 1;
    position: relative;
    transition: transform $transition-time-fast linear;
    &:nth-child(1), &:nth-child(3){
      position: absolute;
      z-index: 2;
      img{
        max-width: 345px;
      }
    }
    &:nth-child(1){
      top: -$spacer*9;
      left: 0;
      transform: rotateZ(10deg);
      &:hover{
        transform: rotateZ(12deg) translate3d(0,-10px,0);
      }
    }
    &:nth-child(3){
      transform: rotateZ(-10deg);
      bottom: -$spacer*10;
      right: 0;
      &:hover{
        transform: rotateZ(-12deg) translate3d(0,-10px,0);
      }
    }
    &:nth-child(2){
      img{
        max-width: 384px;
      }
     &:hover{
       transform: rotateZ(-2deg) translate3d(0,-10px,0);
     } 
    }
  }
}

@include media-breakpoint-down(md){
  .image-collage{
    > a:nth-child(2){
      img{
        max-width: 100%;
      }
    }
  }
}