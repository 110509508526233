// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:     #659A8C;
$primary-2:     #B89666;
$primary-3:     #1D1D22;
$primary-4:     #eae0d1;


// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "primary-4":  $primary-4
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "primary-4":  $primary-4
);